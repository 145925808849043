import React, { useState, useEffect, useRef } from "react";
import { throttle } from "lodash";
// import { handleCloseChatPanel } from "../services/socketClient";
import { useAppSelector, useAppDispatch } from "../redux/hooks";
import { toggleChatTray, renderHtmlInTray } from "../redux/chatbotSlice";
import { getPlatform, isOnMobile } from "../helper/BrowserHelper";
import cmsConfig from "../config/cmsConfig";

const ChatHeader: React.FC = () => {
  const [splashSvgIconContainerWidth, setSplashSvgIconContainerWidth] =
    useState(0);
  const { chatMessage, isChatTrayOpen, showBotHeader } = useAppSelector(
    (state) => state.chatbot
  );
  const hideSplashScreenInApp = chatMessage.length > 1 && !showBotHeader;
  const splashSvgIconContainerRef = useRef<HTMLDivElement | null>(null);

  const dispatch = useAppDispatch();

  //Added below function to make svg responsive
  const onWindowResize = throttle(() => {
    if (splashSvgIconContainerRef.current) {
      setSplashSvgIconContainerWidth(
        splashSvgIconContainerRef.current.clientHeight
      );
    }
  }, 50);

  useEffect(() => {
    window.addEventListener("resize", onWindowResize);
    onWindowResize();

    return () => {
      window.removeEventListener("resize", onWindowResize);
    };
  });

  const renderTrayToggleButton = () => {
    if (!isChatTrayOpen) {
      return (
        <div
          className={`${
            isOnMobile() || getPlatform() === "App"
              ? "chat-header-tray-toggle-mobile"
              : "chat-header-tray-toggle"
          }`}
          onClick={handleTrayToggleAction}
        >
          &lt; Terms & Conditions
        </div>
      );
    }
  };

  const renderSplashScreen = () => {
    return (
      <div className="chat-splash-screen">
        <div
          className="icon-container"
          ref={splashSvgIconContainerRef}
          key="icon-container"
          style={{ width: splashSvgIconContainerWidth }}
        >
          <p className="splashImg" />
        </div>
        <div
          className="headline"
          dangerouslySetInnerHTML={{
            __html: cmsConfig.vfoSplashScreenHeadline,
          }}
        />
      </div>
    );
  };

  const handleTrayToggleAction = () => {
    if (isChatTrayOpen === false) {
      dispatch(toggleChatTray(true));
    } else {
      dispatch(toggleChatTray(false));
      dispatch(
        renderHtmlInTray({
          isRenderHtmlInTray: false,
        })
      );
    }
  };

  return chatMessage.length < 1 ? (
    <div
      className={`${"chat-header"} ${
        hideSplashScreenInApp ? "hide-element" : ""
      } ${isChatTrayOpen ? "chat-tray-open-header" : ""}`}
    >
      <div className="chat-title-container">
        <div className="screen-title-wrapper">{renderSplashScreen()}</div>
      </div>
    </div>
  ) : (
    <div
      className={`${"chat-header-background"} ${
        hideSplashScreenInApp ? "hide-element" : ""
      } ${isChatTrayOpen ? "chat-tray-open-header" : ""}`}
    >
      {renderTrayToggleButton()}
      <p
        className={`${
          isOnMobile() || getPlatform() === "App"
            ? "ask-hana-mobile"
            : "ask-hana"
        }`}
      >
        Ask One Assistant
      </p>
    </div>
  );
};

export default ChatHeader;
