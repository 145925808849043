const toolConfig = {
  lex: {
    errorMsg: "Oops! Something's gone wrong. Please try refreshing the page.",
  },
  livechatwelcomemsg: "My name is <agentName>",
  surveyprompt: "Please select a number",
  assist: { switch: "on" },
  assistApi: "https://s.vodafone.co.nz/",
  tray: {
    contentlist: {
      list1: {
        title: "Terms & Conditions",
        content:
          "<p class='tray-notice'>All the details you need to understand exactly what you can expect as a One NZ customer.</p><a href='https://one.nz/legal/terms-conditions/general/' class='link-text open-in-tray'>General Terms and Conditions</a><a href='https://one.nz/legal/policy/privacy/' class='link-text open-in-tray'>One New Zealand Privacy Policy</a><a href='https://one.nz/legal/terms-conditions/ai-assistant-agent/' class='link-text open-in-tray'>One Assistant Terms & Conditions</a><a href='https://one.nz/legal/change-fees/' class='link-text open-in-tray'>All Change fees</a><a href='https://one.nz/legal/terms-conditions/' class='link-text open-in-tray'>All Other Terms and Conditions</a><p class='tray-notice'>One Assistant is not authorised to waive or change any charges.</p>",
      },
    },
  },
  sales: {
    urlList: [
      "/specials-promos/",
      "/iphone/iphone-12-pro-max/",
      "/broadband/",
      "/broadband/internet-plans/",
      "/broadband/internet-plans/plan-options/",
      "/broadband/rural/",
      "/home-wireless-broadband/",
      "/broadband/moving-home/",
      "/broadband/ultra-fast-fibre/",
      "/mobile-broadband/",
      "/broadband/ultrahub-modem/",
      "/broadband/what-can-i-get/",
      "/mobile-broadband/pocket-wifi/",
      "/help/broadband-and-tv/modem-support/",
      "/business/wireless-broadband/",
    ],
    autoOpenTime: "15",
    salesLiveIntent: "sales.on_web",
    weekdaysHours: "7-24",
    salesLeaveIntent: "sales.on_web_closed",
    weekendsHours: "9-18",
  },
};

export default toolConfig;
