const cmsConfig = {
  vfoMultiSearchInputHint: ["Ask me a question", "How can I help?"],
  vfoHeadline: "<strong>Hana </strong> - Digital Assistant",
  vfoBotRestartChatLabel: "Start again",
  vfoSplashScreenHeadline: "Kia ora, I'm your <strong>One Assistant</strong>",
  vfoWebServiceErrors: [
    {
      id: 1575614769750,
      assets: [],
      vfoDynamicAttributes: {},
      vfoWebServiceErrorMessage: "restart",
      vfoWebServiceErrorCode: 500,
      name: "Restart_Conversation",
      subtype: "WebServiceError",
      vfoIsFatalFormError: "true",
      vfoWebServiceException: "restart",
      vfoErrorMessageText: "Reset <time> - Let's start again",
      type: "VFOConfiguration",
      quick_replies: [{}],
      text: "",
    },
    {
      id: 1542069189547,
      assets: [],
      vfoDynamicAttributes: {},
      vfoWebServiceErrorMessage: "inactivity",
      vfoWebServiceErrorCode: 500,
      name: "User_Inactivity",
      subtype: "WebServiceError",
      vfoIsFatalFormError: "true",
      vfoWebServiceException: "inactivity",
      vfoErrorMessageText:
        "Chat closed due to inactivity. Thank you for chatting with me.",
      type: "VFOConfiguration",
      quick_replies: [{}],
      text: "",
    },
    {
      id: 1542069341545,
      assets: [],
      vfoDynamicAttributes: {},
      vfoWebServiceErrorMessage: "default",
      vfoWebServiceErrorCode: 500,
      name: "Default_BOT_ERROR",
      subtype: "WebServiceError",
      vfoIsFatalFormError: "true",
      vfoWebServiceException: "default",
      vfoErrorMessageText: "Thank you for chatting with me",
      type: "VFOConfiguration",
      quick_replies: [{}],
      text: "",
    },
  ],
  urgentInfo: "",
  welcomeMessages: [
    "Kia ora, I'm One Assistant.",
    "One Assistant uses artificial intelligence (AI) to generate answers to your questions based on content from One New Zealand's website.",
    "I can help with general queries and if needed, connect you to a human teammate.",
    "While we strive to offer accurate and helpful information, we recommend you verify information using our website and customer support team.",
    "We collect information to verify your identity and do our best to assist with your enquiries. For more details, check out our <a href='https://one.nz/legal/policy/privacy/'>Privacy Policy</a> and the <a href='https://one.nz/legal/terms-conditions/ai-assistant-agent/'>One Assistant Terms and Conditions</a>",
  ],
};

export default cmsConfig;
